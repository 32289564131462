const initialState = {
    contacts: [],
    contactsData: {}
}

export const contactsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CONTACTS':
            return {...state, contacts: action.payload}
        case 'SET_CONTACTS_DATA':
            return {...state, contactsData: action.payload}
        default:
            return state
    }
}