const initialState = {
    cityName: '',
    deliveyCityData: {},
    deliveyCityDataLoaded: false,
    shops: {},
    edostMinDays: 0,
    relocations: [],
    citys: [],
    sdekPoints: [],
    edostPoints: [],
    domains: {}
}

export const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CITY_NAME':
            return {...state, cityName: action.payload}
        case 'SET_DELIVERY_CITY_DATA':
            return {...state, deliveyCityData: action.payload}
        case 'SET_DELIVERY_CITY_DATA_LOADED':
            return {...state, deliveyCityDataLoaded: action.payload}
        case 'FETCH_SHOPS':
            return {...state, shops: action.payload}
        case 'SET_EDOST_MIN_DAYS':
            return {...state, edostMinDays: action.payload}
        case 'FETCH_RELOCATIONS':
            return {...state, relocations: action.payload}
        case 'SET_CITYS':
            return {...state, citys: action.payload}
        case 'SET_SDEK_POINT':
            return {...state, sdekPoints: action.payload}
        case 'SET_EDOST_POINT':
            return {...state, edostPoints: action.payload}
        case 'SET_DOMAINS':
            return {...state, domains: action.payload}
        default:
            return state
    }
}