// @ts-ignore
import {combineReducers} from 'redux'
import {sectionsReducer} from "./sectionsReducer";
import {sectionReducer} from "./sectionReducer";
import {productReducer} from "./productReducer";
import {breadcrumbsReducer} from "./breadcrumbsReducer";
import {HYDRATE} from "next-redux-wrapper";
import {modelsReducer} from "./modelsReducer";
import {popupReducer} from "./popupReducer";
import {userReducer} from "./userReducer";
import {cartReducer} from "./cartReducer";
import {locationReducer} from "./locationReducer";
import {contactsReducer} from "./contactsReducer";
import {filterReducer} from "./filterReducer";
import {contentReducer} from "./contentReducer";
import {newsReducer} from "./newsReducer";
import {deliveryReducer} from "./deliveryReducer";
import {servicesReducer} from "./servicesReducer";
import {brandsReducer} from "./brandsReducer"
import {pageStateReducer} from "./pageStateReducer"
import {metaReducer} from "./metaReducer"
import {propsReducer} from "./propsReducer"
import {baltexReducer} from "~/redux/reducers/baltexReducer";

const rootReducer = combineReducers({
    sections: sectionsReducer,
    section: sectionReducer,
    product: productReducer,
    models: modelsReducer,
    breadcrumbs: breadcrumbsReducer,
    popup: popupReducer,
    user: userReducer,
    cart: cartReducer,
    location: locationReducer,
    contacts: contactsReducer,
    filter: filterReducer,
    content: contentReducer,
    news: newsReducer,
    delivery: deliveryReducer,
    services: servicesReducer,
    brands: brandsReducer,
    pageState: pageStateReducer,
    meta: metaReducer,
    props: propsReducer,
    baltex: baltexReducer,
})

export const reducer = (state: any, action: any) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        };
        //nextState.cart = state.cart;
        //nextState.contacts = state.contacts;
        //nextState.shops = state.shops;
        //nextState.location = state.location;
        //nextState.models = state.models;
        nextState.content.catalogBanners = state.content.catalogBanners;
        nextState.user = state.user;
        return nextState;
    } else {
        return rootReducer(state, action);
    }
};