const initialState = {
    sections: [],
}

export const baltexReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BALTEX_SECTIONS':
            return {...state, sections: action.payload}
        default:
            return state
    }
}