const initialState = {
    deliveryData: {},
    deliveryInfo: {},
}

export const deliveryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DELIVERY':
            return {...state, deliveryData: action.payload}
        case 'FETCH_DELIVERY':
            return {...state, deliveryInfo: action.payload}
        default:
            return state
    }
}