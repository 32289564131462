const initialState = {
    items: [],
    detail: {},
}

export const brandsReducer = (state = initialState, action) => {
    switch (action.type) {
    case 'FETCH_BRANDS':
        return {...state, items: action.payload}
    case 'FETCH_BRAND_DETAIL':
        return {...state, detail: action.payload }
    default:
        return state
    }
}