const initialState = {
    services: [],
    detail: {},
    detailInstall: {},
}

export const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SERVICES':
            return {...state, services: action.payload}
    case 'FETCH_SERIVCE':
        return {...state, detail: action.payload }
    case 'FETCH_SERIVCE_INSTALL':
        return {...state, detailInstall: action.payload }
    default:
        return state
    }
}